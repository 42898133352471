<template>
  <div class="units-allocation">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="30vh"
               class="allocation-dialog">
      <div slot="title"
           class="units-allocation-form__header">
        <span>一键发券</span>
      </div>
      <el-form :model="form"
               ref="form"
               :rules="rules"
               label-position="right"
               label-width="120px">
        <div class="units-allocation-form__body">
          <el-form-item label="选择票券："
                        prop="type">
            <el-select v-model="form.type"
                       placeholder="请选择票券"
                       filterable>
              <el-option v-for="(option,index) in optionsResult"
                         :label="option.name"
                         :value="option.id"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="每人发放数量："
                        prop="number">
            <el-input placeholder="请输入发放数量"
                      v-model="form.number"
                      @input="limitPositiveInteger"
                      clearable
                      maxlength="2"></el-input>
          </el-form-item>
          <el-form-item label="发券文档："
                        prop="file">
            <el-upload ref="upload"
                       :action="uploadUrl"
                       list-type="picture-card"
                       :headers="headers"
                       :before-upload="beforUpload"
                       :on-success="successUpload"
                       :limit="1"
                       name="file"
                       accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                       :multiple="false"
                       :disabled="form.file ? true : false"
                       :on-exceed="limitFn"
                       :on-remove="handleRemove">
              <div class="el-upload__tip"
                   slot="tip">支持格式：xls、xlxs，大小不超过20M。</div>
              <i class="el-icon-plus"></i>
              <div slot="file"
                   slot-scope="{file}">
                <img class="el-upload-list__item-thumbnail"
                     src="../../../assets/img/yimi.png" />
                <span class="el-upload-list__item-actions">
                  <span class="el-upload-list__item-preview"
                        @click="handlePictureCardPreview(file)">
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span class="el-upload-list__item-delete"
                        @click="handleDownload(file)">
                    <i class="el-icon-download"></i>
                  </span>
                  <span class="el-upload-list__item-delete"
                        @click="handleRemove(file)">
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
          </el-form-item>
        </div>

      </el-form>
      <div slot="footer"
           class="units-allocation-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('form')">确定</el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'
import { getToken } from '@/utils/localStorage.js'

export default {
  data() {
    // 校验记账单位是否录入正确
    // 校验记账单位数量是否录入正确
    // TODO:记账单位的分配不能超过剩余的记账单位
    let validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入发放数量'))
      } else {
        if (!/^(10|[1-9])$/.test(value)) {
          return callback(new Error('请输入不超过10的发放数量'))
        } else {
          return callback()
        }
      }
    }
    return {
      form: {
        type: '',
        number: '',
        file: ''
      },
      // 校验规则
      rules: {
        type: [{
          validator: (rule, val, callback) => {
            if (val) {
              return callback()
            } else {
              return callback(new Error('请选择票券'))
            }
          }
        }],
        number: [
          { validator: validateNumber, trigger: 'blur' }
        ],
        file: [
          {
            validator: (rule, val, callback) => {
              if (this.form.file) {
                return callback()
              } else {
                return callback(new Error('请上传发券文档'))
              }
            }
          }
        ]
      },
      optionsResult: [], // 远程搜索的记账单位结果
      dialogWidth: '0', // 窗口宽度
      loading: false,
      visible: false,
      dialogImageUrl: '',
      hash: '',
      fileName: ''
      // options: []
    }
  },
  props: {
    // 是否显示分配记账单位窗口
    // 当前行信息
    rowData: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    uploadUrl() {
      return window.BASE_URL + '/upload'
    },
    headers() {
      return { sessionId: getToken() }
    }
  },
  mounted() {
    this.visible = true
    this.setDialogWidth()
    this.getList()
    // 监听浏览器窗口大小变化，用于分配记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.showAllocation) {
        return this.setDialogWidth()
      }
    }, false)
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener('resize', () => {
      if (this.showAllocation) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    showAllocation() {
      if (this.showAllocation) {
        this.setDialogWidth()
      }
    }
  },
  methods: {
    // 清楚窗口中已输入的内容
    clearForm() {
      this.form.type = ''
      this.form.number = ''
      this.form.file = ''
      this.fileName = ''
      this.hash = ''
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
      this.clearForm()
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let unitData = deepClone(this.form)
          this.$emit('submit', unitData, this.rowData)
          // this.handleCancel()
          // this.clearForm()
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414 && windowWidth < 680) {
        this.dialogWidth = '440px'
      } else if (windowWidth > 680) {
        this.dialogWidth = '600px'
      }
    },
    // 控制记账单位只能输入正整数
    limitPositiveInteger() {
      if (this.form.number.length === 1) {
        this.form.number = this.form.number.replace(/[^1-9]/g, '')
      } else {
        this.form.number = this.form.number.replace(/\D/g, '')
      }
    },
    // 远程搜索记账单位
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        // 获取后端返回记账单位记录
      } else {
        this.optionsResult = []
      }
    },
    handleRemove(file, fileList) {
      this.$http.file.removeFile({
        fileName: this.fileName,
        hash: this.hash
      }).then(res => {
        this.form.file = ''
        this.fileName = ''
        this.hash = ''
        if (res.data.code === 0) {
          this.$refs.upload.clearFiles()
        } else {
          this.$refs.upload.clearFiles()
        }
      })
    },
    beforUpload(file) {
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          type: 'warning',
          message: '文件大小不能超过20M'
        })
        return false
      }
    },
    successUpload(res, file, fileList) {
      this.form.file = res.data.path
      this.hash = res.data.hash
      this.fileName = res.data.fileName
    },
    limitFn(files, fileList) {
      this.$message({
        type: 'warning',
        message: '最多上传一个文件'
      })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
    },
    handleDownload(file) {
      console.log(file)
    },
    getList() {
      this.$http.ticket.getTicketList({
        'ticketName': '',
        'statusList': ['未发放', '发放中'],
        'creatorList': [],
        'page': 1,
        'size': 1000,
        'onlyOwn': true
      }).then(res => {
        if (res.data.code === 0) {
          this.optionsResult = res.data.data.records.filter(item => {
            if (item.type !== '5') {
              return item
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.allocation-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 103px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.units-allocation {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }

    &__info {
      position: relative;
      top: unset;
      left: unset;
      user-select: none;
      padding: 10px 0;
      color: $--color-text-primary;
      display: flex;
      align-items: center;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-base;
        margin-right: 33px;
        color: $--color-text-primary;
        flex-grow: 1;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 10px;
    }
  }
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 134px;
  }
}

@media screen and (max-width: 375px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 208px;
        }
      }
    }
  }
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 127px;
  }
}

@media screen and (max-width: 320px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 195px;
        }
      }
    }
  }
}
.text-center {
  text-align: center;
}
</style>
