<template>
  <div class="units-allocation">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="20vh"
               :lock-scroll="true"
               class="allocation-dialog">
      <div slot="title"
           class="units-allocation-form__header">
        <span>发券提示</span>
      </div>
      <el-row  class="row">
        <el-col :span="12">
          <h3 class="text-center">发券成功:{{successNum}}个</h3>
          </el-col>
        <el-col :span="12">
          <h3 class="text-center">发券失败:{{failNum}}个</h3>
          </el-col>
      </el-row>
        <ym-table-list :data="data"
                      :columns="columns"
                      :enablePagination="false"
                      max-height="720px"
                      >
            <template #message="{row}">
              <template v-if="row.code ===0">
                <el-alert type="success"  show-icon :title="row.message" :closable="false"> </el-alert>
              </template>
              <template v-else-if="row.code ===1"><el-progress :percentage="row.progress"></el-progress></template>
              <template v-else>  <el-alert type="error"  show-icon  :title="row.message" :closable="false"> </el-alert></template>
            </template>
        </ym-table-list>
      <div slot="footer"
           class="units-allocation-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleCancel">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import { _local } from '@/utils/localStorage.js'
let Base64 = require('js-base64').Base64
export default {
  components: {
    YmTableList
  },
  props: {
    flagId: String
  },
  data() {
    return {
      data: [],
      dialogWidth: '0', // 窗口宽度
      loading: false,
      visible: false,
      columns: [
        {
          label: '序号',
          type: 'index',
          align: 'center'
        }, {
          label: '发送对象',
          prop: 'phone',
          align: 'center'
        },
        {
          label: '备注',
          prop: 'message',
          align: 'center',
          enableColSlot: true
        }],
      wsObject: null,
      wsReady: true,
      failNum: 0,
      successNum: 0
      // options: []
    }
  },
  mounted() {
    this.initWebsocket()

    this.visible = true
    this.setDialogWidth()
    // 监听浏览器窗口大小变化，用于分配记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.showAllocation) {
        return this.setDialogWidth()
      }
    }, false)
  },
  beforeDestroy() {
    // 移除事件监听
    this.wsObject.close()
    this.wsObject = null
    console.log(this.wsObject)
    window.removeEventListener('resize', () => {
      if (this.showAllocation) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    showAllocation() {
      if (this.showAllocation) {
        this.setDialogWidth()
      }
    }
  },
  methods: {
    initWebsocket () {
      let _this = this
      this.wsObject = new WebSocket(window.BASE_URL_WS + '/websocket/' + Base64.encode(_local.get('sessionId')))
      this.wsObject.onopen = function (data) {
        _this.wsReady = true
        console.log('ready')
        _this.wsObject.send(JSON.stringify({
          'type': 'ticket',
          'versionId': '1',
          'content': _this.flagId
        }))
      }
      this.wsObject.onmessage = function (evt) {
        const data = JSON.parse(evt.data)
        console.log(data)
        if (data.type === 'ticket') {
          _this.data = JSON.parse(data.content)
          _this.successNum = data.successNum
          _this.failNum = data.failNum
        }
      }
      this.wsObject.onclose = function (evt) {
        console.log('Connection closed. ')
      }
      this.wsObject.onerror = function () {
        // 重连逻辑 待定
      }
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414 && windowWidth < 680) {
        this.dialogWidth = '440px'
      } else if (windowWidth > 680) {
        this.dialogWidth = '600px'
      }
    }

  }

}
</script>

<style lang="scss">
.allocation-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 103px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.units-allocation {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 260px;
        }
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }

    &__info {
      position: relative;
      top: unset;
      left: unset;
      user-select: none;
      padding: 10px 0;
      color: $--color-text-primary;
      display: flex;
      align-items: center;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-base;
        margin-right: 33px;
        color: $--color-text-primary;
        flex-grow: 1;
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 10px;
    }
  }
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 134px;
  }
}

@media screen and (max-width: 375px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 208px;
        }
      }
    }
  }
  .allocation-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
  .units-allocation-form__info {
    top: 207px;
    left: 127px;
  }
}

@media screen and (max-width: 320px) {
  .units-allocation-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 195px;
        }
      }
    }
  }
}
.text-center {
  text-align: center;
}
.row{
  padding-bottom: 10px;
}
</style>
