import { saveFileToLink } from 'web-downloadfile'

export function fileDownload (link, fileName, type) {
// 主要用于pdf文件链接的下载，因为pdf文件链接在浏览器会直接打开，但是其他文件的链接也可以下载，可监听文件下载进度
// link必须允许跨越访问，否则无法下载
// link为文件下载地址，fileName为下载的文件名，type为下载的文件类型
  const reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~/])+$/
  if (!reg.test(link)) {
    throw new Error('传入参数不合法,不是标准的文件链接')
  } else {
    saveFileToLink(link, fileName, type)
  }
}
